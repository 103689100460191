import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import Markdown from 'react-markdown'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Hero from '../components/Hero'
import Breadcrumbs from '../components/Breadcrumbs'
import ImageGallery from '../components/ImageGallery'

const ArticleContent = ({ article }) => {
  const imageGallery = article.image_gallery

  if(imageGallery){
    return(
      <div className="column">
        <ImageGallery images={article.images} />
      </div>
    )
  }
  return(
    <>
      {article.images && article.images.length > 0 &&
        <div className="column">
          {article.images.map((image, i) => (
            <GatsbyImage
              key={i}
              image={image.localFile.childImageSharp.gatsbyImageData}
              alt={image.alternativeText ? image.alternativeText : ""}
              style={{ margin: '1rem 0' }}
            />
          ))}
        </div>
      }
      <div className="column">
        <div className="">
          <Markdown children={article.content} />
        </div>
      </div>
    </>
  )
}


const ArticleTemplate = ({ data }) => {
  const article = data.strapiArticle

  return (
    <Layout>
      <Seo />
      <Hero title={article.title}/>
      <section className="section">
        <Breadcrumbs article={article} />
      </section>

      <section className="section">
        <div className="container">
          <h1 className="title">{article.title}</h1>

          <div className="columns">
            <ArticleContent article={article} />
          </div>

        </div>
      </section>
    </Layout>
  )
}

export default ArticleTemplate


export const query = graphql `
  query ArticleTemplateQuery($id: String!) {
    strapiArticle(id: {eq: $id}) {
      strapiId
      title
      slug
      content
      published_at
      category {
        name
        slug
      }
      image_gallery
      images {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`
