import React from 'react'
import { Link } from 'gatsby'

const Breadcrumbs = ({category, article}) => {

  return(
    <nav className="breadcrumb" aria-label="breadcrumbs">
      <ul>
        <li>
          <Link to="/">Home</Link>
        </li>
        {category &&
        <>
          <li className="is-active">
            <Link to={`/${category.slug}`}>
              {category.name}
            </Link>
          </li>
        </>
        }
        {article &&
        <>
          <li>
            <Link to={`/${article.category.slug}`}>
              {article.category.name}
            </Link>
          </li>
          <li className="is-active">
            <Link to={`/${article.category.slug}/${article.slug}`}>
              {article.title}
            </Link>
          </li>
        </>
        }

      </ul>

    </nav>
  )
}

export default Breadcrumbs
