import React from 'react'

import { GatsbyImage } from 'gatsby-plugin-image'

const ImageGallery = ({images}) => {

  return (
    <div className="columns is-multiline is-mobile">
        {images.map((image, i) => (
          <div className="column is-half" key={i}>
            <GatsbyImage
              image={image.localFile.childImageSharp.gatsbyImageData}
              alt={image.alternativeText ? image.alternativeText : ""}
              style={{ margin: '1rem 0' }}
            />
          </div>
        ))}
    </div>
  )
}

export default ImageGallery
